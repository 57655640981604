const VIEW_CONSTANTS = {
    COOKIE_POLICY_VIEW: "COOKIE_POLICY",
    TOKEN_EXPIRY_VIEW: "TOKEN_EXPIRY"
};

const TAB_SWITCH_EVENT = "TAB_SWITCH_EVENT";
const PAUSE_TIMER_EVENT = "PAUSE_TIMER_EVENT"
const RESUME_TIMER_EVENT = "RESUME_TIMER_EVENT"
const HIDE_GAME_IFRAME_EVENT = "HIDE_GAME_IFRAME_EVENT"

export {
    VIEW_CONSTANTS,
    TAB_SWITCH_EVENT,
    PAUSE_TIMER_EVENT,
    RESUME_TIMER_EVENT,
    HIDE_GAME_IFRAME_EVENT
};