import React, { useCallback, useEffect, useRef, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import Toast from 'components/dumb/Toast';
import GlobalHeader from 'components/dumb/GlobalHeader';
import WebcamCapture from 'components/dumb/WebCam/WebCam';
import UnberryModal from 'components/dumb/UnberryModal';
import FullscreenWarning from './FullscreenWarning';
import PermissionsModal from 'components/dumb/PermissionsModal';
import { storage } from 'services/config/storage';
import GameplayApi from '../../services/apis/gameplay';
import {
  capitalizeFirstLetter,
  getListOfGames, setDocumentDetails,
} from 'utils/helper';
import { updateFCData } from 'services/freshchat';
import ProctoringApi from 'services/apis/proctoring';
import candidateFormApi from 'services/apis/form';
import { useGameplayConfig } from 'store';
import {
  useFullScreen,
  useGamesLifecycle,
  useIsMobileDevice,
  usePauseResumeTimer,
  useTabSwitch,
  useVideoRecorder
} from 'hooks';
import { GC } from 'utils/postMessage';
import { DEFAULT_COMPANY_NAME, PERMISSION_DEVICE_TYPES } from 'constants/globalConstants';
import { GAME_COMPLETION_STATUS } from 'utils/consts';
import './style.scss';
import { HIDE_GAME_IFRAME_EVENT, PAUSE_TIMER_EVENT } from 'constants/helperModalConstants';

const GameLoader = (props) => {
  /**
   * ===================
   * useStates
   * ===================
   */
  const [currentGame, setCurrentGame] = useState({});
  const [availableGames, setAvailableGames] = useState([]);
  const [playedGamesIds, setPlayedGamesIds] = useState(
    storage.get.listOfPlayedGamesIds()
  );
  const [allowedOrigins, setAllowedOrigins] = useState([]);
  const [imgSrc, setImgSrc] = useState('');
  const [isFullScreenEnabled, setIsFullScreenEnabled] = useState(true)
  const [fullScreenWarningVisible, setFullScreenWarningVisible] = useState(false)
  const [userDisqualificationReason, setUserDisqualificationReason] = useState("")
  const [showTabWarning, setShowTabWarning] = useState(false);
  const [gameplayPreRequisitesLoading, setGameplayPreRequisitesLoading] = useState(false)
  const [hideGameIframe, setHideGameIframe] = useState(false);
  const {
    isProctored,
    isVideoProctored,
    isFullScreen,
    tabSwitchLimit,
    snapshotInterval,
    fetchConfig,
    configLanguageSettings
  } = useGameplayConfig()

  const { configLanguage } = configLanguageSettings;

  /**
   * ===================
   * useRefs
   * ===================
   */
  const frameRef = useRef(null);
  const loaderRef = useRef(null);
  const pauseGameTimerOnMount = useRef(false)

  /**
   * ===================
   * constant variables
   * ===================
   */

  /**
   * ===================
   * Custom hooks
   * ===================
   */
  const history = useHistory()
  const { id: linkId } = useParams()
  const isMobile = useIsMobileDevice()
  const { isTabWarningFinal } = useTabSwitch({
    tabSwitchLimit,
    setUserDisqualificationReason,
    setShowTabWarning,
    linkId,
  })
  const { disqualifyFullScreen } = useFullScreen({
    setIsFullScreenEnabled,
    isFullScreenEnabled,
    isFullScreen,
    setFullScreenWarningVisible,
    setUserDisqualificationReason,
  })
  const { isPermissionModalVisible } = useVideoRecorder()
  usePauseResumeTimer(currentGame)

  /**
 * ===================
 * functions
 * ===================
 */

  const redirectToExternalUrl = (url) => {
    window.location.replace(url);
  };

  const markGameplayAsEnd = async (disqualifyData = {}) => {
    if (document.title.toLowerCase().includes(DEFAULT_COMPANY_NAME)) {
      document.title = `Unberry`;
    } else {
      const [companyName] = setDocumentDetails();
      document.title = `${capitalizeFirstLetter(companyName)}`;
    }
    const callbackUrl = storage.get.url();
    storage.destroy.url();
    try {
      const sendEmail = !callbackUrl;
      const response = await GameplayApi.quitUnberryGamePlay(linkId, sendEmail, disqualifyData);
      if (response?.skippedGames?.length > 0) {
        initSkippedGames(response.skippedGames)
        return
      }
      if (isVideoProctored) {
        await ProctoringApi.markVideoProctoringComplete(linkId);
      }

      // sending postmessage event for iframe communication
      // Need to send this for SDK purpose
      if (window.location !== window.parent.location) {
        GC.sendGameplayEndMessage()
      }

      if (callbackUrl) {
        redirectToExternalUrl(callbackUrl);
      } else {
        const { id: userId } = storage.get.fcData()
        history.push({
          pathname: `/end/${userId}/${linkId}`, state: {
            disqualifyReason: disqualifyData?.disqualifyReason ?? ""
          }
        });
      }
    } catch (error) {
      Toast('error', error.message);
      if (callbackUrl) {
        redirectToExternalUrl(callbackUrl);
      } else {
        history.push(`/home/${linkId}`);
      }
      console.log('error', error);
    }
  };

  const initSkippedGames = (skippedGames = []) => {
    if (skippedGames.length > 0) {
      skippedGames = skippedGames
        .map(skippedGame => {
          skippedGame = {
            ...skippedGame,
            url: skippedGame?.deeplink,
            name: skippedGame?.name ?? skippedGame?.title,
            uniqueId: `${new Date().getTime() + Math.random()}`,
          }
          delete skippedGame?.deeplink
          delete skippedGame?.title
          return skippedGame
        })

      const unskippedAvailableGames = availableGames
        .filter(availableGame => !skippedGames?.find(skippedGame => availableGame.id === skippedGame.id))
      const updatedAvailableGames = [...unskippedAvailableGames, ...skippedGames]
      setAvailableGames(updatedAvailableGames)
      setAllowedOrigins(updatedAvailableGames.map(({ url }) => url))

      const latestPlayedGameIds = storage.get.listOfPlayedGamesIds()
      const updatedPlayedGameIds = latestPlayedGameIds
        .filter(playedGameId => !skippedGames?.find(skippedGame => skippedGame.id === playedGameId))
      storage.set.listOfPlayedGamesIds(updatedPlayedGameIds)
      setPlayedGamesIds(updatedPlayedGameIds)

      if (loaderRef.current) loaderRef.current.style.display = 'flex'
      const [nextGame] = skippedGames
      setCurrentGame(nextGame)
    } else {
      markGameplayAsEnd()
    }
  }

  const initGame = useCallback(
    (updatedPlayedGamesIds = []) => {
      // saving update ids
      storage.set.listOfPlayedGamesIds(updatedPlayedGamesIds);
      setPlayedGamesIds(updatedPlayedGamesIds);

      // filtering unplayed games based on gameIds
      // Todo: unplayedGames should not have duplicates entries
      // Todo: pass gameComplete flag at the end of each game api call
      const unplayedGames = availableGames.filter(
        (game) => !updatedPlayedGamesIds.includes(game.id)
      );

      if (unplayedGames.length > 0) {
        // load first game from the unplayedGames
        if (loaderRef.current) loaderRef.current.style.display = 'flex';
        const [nextGame] = unplayedGames;

        // No need to use this now because of key on iframe
        // if (
        //   nextGame?.url === currentGame?.url &&
        //   nextGame.id !== currentGame?.id
        // ) {
        //   // to re-render the frame to mount the game again
        //   setCurrentGame({});
        // }

        setCurrentGame(nextGame);

      } else if (
        unplayedGames.length === 0 &&
        availableGames.length !== 0 &&
        updatedPlayedGamesIds.length >= availableGames.length
      ) {
        markGameplayAsEnd();
      }
    },
    [availableGames, history, linkId, currentGame]
  );

  const handleHideGameEvent = () => setHideGameIframe(true)

  /**
   * ===================
   * useEffects
   * ===================
   */
  useEffect(() => {
    window.addEventListener(HIDE_GAME_IFRAME_EVENT, handleHideGameEvent)
    return () => {
      window.removeEventListener(HIDE_GAME_IFRAME_EVENT, handleHideGameEvent)
    }
  }, [currentGame])

  useEffect(() => {
    (async () => {
      if (userDisqualificationReason?.length > 0) {
        markGameplayAsEnd({
          disqualifyReason: userDisqualificationReason
        })
      }
    })()
  }, [userDisqualificationReason])

  useEffect(() => {
    if (hideGameIframe) {
      // Setting a timeout for GC to load iframe and dispatch pause timer event
      setTimeout(() => {
        window.dispatchEvent(new Event(PAUSE_TIMER_EVENT))
      }, 1000)
    }
  }, [hideGameIframe])

  useEffect(() => {
    let didCancel = false; // to avoid state update after component unmount
    const getGames = async () => {
      console.log(`[Gettig list of games] for link: `, linkId);
      const games = await getListOfGames(linkId); // get list of games for a linkId
      console.log(`[Games recieved] success: `, JSON.stringify(games));
      if (games) {
        const completedGameIds = games.filter(game => game.status === GAME_COMPLETION_STATUS.COMPLETED)?.map(game => game.id)
        storage.set.listOfPlayedGamesIds(completedGameIds)
        setPlayedGamesIds(completedGameIds)
        if (!didCancel) {
          setAvailableGames(games);
          setAllowedOrigins(games.map(({ url }) => url));
        }
      } else {
        // if games data is not present logout the user
        history.push(`/home/${linkId}`);
        storage.destroy.all();
      }
    };

    const lsLinkId = storage.get.linkId()
    if (lsLinkId != linkId) {
      history.push(`/home/${linkId}`)
      storage.destroy.all()
    } else {
      getGames();
    }

    return () => {
      didCancel = true;
    };
  }, []);

  useEffect(() => {
    initGame(playedGamesIds);
  }, [availableGames]);

  useEffect(() => {
    if (currentGame.name) {
      const { name: gameName } = currentGame
      if (document.title.toLowerCase().includes(DEFAULT_COMPANY_NAME)) {
        document.title = `Unberry • ${gameName}`
      } else {
        const [companyName] = setDocumentDetails()
        document.title = `${capitalizeFirstLetter(companyName)} • ${gameName}`
      }
      const showGameplayOnly = storage.get.showGameplayOnly()
      if (!showGameplayOnly) {
        updateFCData({ meta: { gameName } }, isMobile)
      }
      checkGameplayPreRequisites()
    }
  }, [currentGame])

  /**
   * For cases where, GC triggers the permission before the game has mounted.
   * Send this value in GAME_MOUNT event in useGamesLifecycle
   */
  useEffect(() => {
    if (isPermissionModalVisible) {
      pauseGameTimerOnMount.current = true
    }
  }, [isPermissionModalVisible])

  useGamesLifecycle({
    allowedOrigins,
    currentGame,
    linkId,
    loaderRef,
    playedGamesIds,
    initGame,
    pauseGameTimerOnMount,
    isFullScreen,
    isMobile,
    disqualifyFullScreen,
    configLanguage,
    hideGameIframe
  })

  const checkGameplayPreRequisites = async () => {
    try {
      setGameplayPreRequisitesLoading(true)
      const { positionConfigRes, linkConfigRes } = await fetchConfig(false)
      if (positionConfigRes?.success) {
        if (!!positionConfigRes?.positionConfig?.customizableForm) {
          const candidateFormData = await candidateFormApi.getCandidateFormData(linkId)
          if (candidateFormData?.success) {
            if (candidateFormData?.userData === null) {
              history.replace(`/form/${linkId}`)
              return
            }
          }
        }
        if (linkConfigRes?.success) {
          if ((!!linkConfigRes?.linkPositionConfig && !!linkConfigRes?.linkPositionConfig?.proctoring)
            || (!!positionConfigRes?.positionConfig?.proctoring)) {
            const response = await ProctoringApi.getUserReferenceImg(linkId)
            if (response.status === 404) {
              history.replace(`/rules/${linkId}`)
              return
            }
          }
        }
      }
    } catch (err) {
      console.log(err)
    } finally {
      setGameplayPreRequisitesLoading(false)
    }
  }

  return (
    <section className="game-container bg-color-primary flex flex-col">
      {isProctored && (
        <WebcamCapture
          imgSrc={imgSrc}
          setImgSrc={setImgSrc}
          linkId={linkId}
          snapshotInterval={snapshotInterval}
          pauseGameTimer={() => {
            pauseGameTimerOnMount.current = true
          }}
        />
      )}
      {availableGames.length > 0 &&
        playedGamesIds.length < availableGames.length ? (
        <GlobalHeader
          currentLoadedGame={currentGame.name}
          currentGameIndex={availableGames.findIndex(
            (game) => game.id === currentGame.id
          )}
          totalAvailableGames={availableGames}
          isFullScreen={isFullScreen}
          isFullScreenEnabled={isFullScreenEnabled}
          isProctored={isVideoProctored}
        />
      ) : null}
      <div id="game-window">
        <div className="game-wrapper">
          <div ref={loaderRef} className="game-loader flex items-center justify-center absolute top-0 left-0">
            <Spin
              indicator={<LoadingOutlined spin style={{ color: "#fff", fontSize: 28 }} />}
            />
          </div>
          <iframe
            key={currentGame?.uniqueId ?? currentGame?.id}
            ref={frameRef}
            id="game-frame"
            src={hideGameIframe ? null : currentGame?.url}
            title="gameFrame"
            allow="microphone; camera"
          />
          <UnberryModal
            visible={showTabWarning}
            onCancel={() => setShowTabWarning(false)}
            footer={false}
            className={'warning-modal'}
          >
            <div className='flex flex-col items-center'>
              {
                isTabWarningFinal ? <p className='size-32'>⚠️</p> : <></>
              }
              <p className="color-primary font-bold size-32 mb-20">
                Tab Switch Detected
              </p>
              {
                isTabWarningFinal
                  ? <>
                    {/* <p className="color-primary font-bold size-18 verify" style={{ lineHeight: '26.5px' }}>
                      Please ensure that you remain on the assessment tab throughout
                      the duration of the test to ensure a fair and accurate assessment of your skills.
                    </p> */}
                    <p className="color-primary font-bold size-18 verify" style={{ lineHeight: '26.5px' }}>
                      Any further attempts to switch tabs or change browser windows will result in disqualification from the assessment.
                    </p>
                  </>
                  : <>
                    <p className="color-primary font-bold size-18 verify" style={{ lineHeight: '26.5px' }}>
                      Please don't switch tabs.
                    </p>
                  </>
              }
              <Button
                className='mt-10'
                onClick={() => setShowTabWarning(false)}
              >
                Understood
              </Button>
            </div>
          </UnberryModal>
          <FullscreenWarning
            fullScreenWarningVisible={fullScreenWarningVisible}
            setFullScreenWarningVisible={setFullScreenWarningVisible}
            disqualifyFullScreen={disqualifyFullScreen}
          />
          <PermissionsModal
            visible={!!isPermissionModalVisible}
            type={isPermissionModalVisible}
            device={PERMISSION_DEVICE_TYPES.CAMERA_MIC}
          />
          {
            gameplayPreRequisitesLoading ? <>
              <div className="game-loader flex items-center justify-center absolute top-0 left-0">
                <Spin
                  indicator={<LoadingOutlined spin style={{ color: "#fff", fontSize: 28 }} />}
                />
              </div>
            </> : <></>
          }
        </div>
      </div>
    </section>
  );
};

export default GameLoader;
