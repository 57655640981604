import { useState, useCallback, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import { Button, Checkbox, Result, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import PermissionsModal from '../PermissionsModal';
import { useImgComparison } from 'hooks';
import { detectFacesInImage } from 'utils/faceDetection';
import { classifyMediaDeviceError, getPrivacyPolicyLink } from 'utils/helper';
import { storage } from 'services/config/storage';
import { Camera, Dropdown } from 'assets/icons';
import { HIDE_GAME_IFRAME_EVENT, PAUSE_TIMER_EVENT } from 'constants/helperModalConstants';
import { PERMISSION_DEVICE_TYPES, PERMISSION_ERRORS } from 'constants/globalConstants';
import { useTranslation } from 'react-i18next';
import { useGameplayConfig } from 'store';

const WebcamCapture = ({
  initialCapture = false,
  isMobile = false,
  snapshotInterval = 60,
  imgSrc,
  setImgSrc,
  confirmPhoto,
  loading,
  linkId,
  consent,
  setConsent,
  isVideoProctored = false,
}) => {
  const webcamRef = useRef(null);

  const { t } = useTranslation();

  const { configLanguageSettings: { isRtlLayout } } = useGameplayConfig()

  const isChrome = /Chrome/.test(navigator.userAgent);
  const privacyPolicyLink = getPrivacyPolicyLink();

  const [webCamKey, setWebCamKey] = useState(0); // for refreshing WebCam component (see useEffect for more info)
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [devices, setDevices] = useState(null);
  const [imageAnalysisData, setImageAnalysisData] = useState(null);
  const [storedDeviceId, setStoredDeviceId] = useState(storage.get.webCam());
  // Check camera permission on component mount -> For image proctoring where it does not trigger immediately on refresh
  const [onMountCamCheck, setOnMountCamCheck] = useState(true);

  const { proctorGameplayCapture } = useImgComparison({ linkId });

  const saveWebCamDevice = (deviceId) => {
    storage.set.webCam(deviceId)
    setStoredDeviceId(deviceId)
  }

  const handleDevices = (mediaDevices, updateDeviceId = false) => {
    const videoSources = mediaDevices.filter(({ kind, label }) => kind === 'videoinput' && !label.toLowerCase().includes('back') && !label.toLowerCase().includes('obs'))
    setDevices(videoSources)
    if (videoSources.length === 0) throw new Error(PERMISSION_ERRORS.NO_DEVICE)
    if (updateDeviceId) {
      const [firstSource] = videoSources ?? [{}]
      saveWebCamDevice(firstSource.deviceId)
    }
  }

  const checkCameraStatus = async (selectedCamDevice) => {
    await navigator.mediaDevices.getUserMedia({
      video: {
        deviceId: selectedCamDevice,
      },
      audio: false,
    })
    if (onMountCamCheck) setOnMountCamCheck(false);
  }

  const capture = async () => {
    try {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices()
      handleDevices(mediaDevices)
      const selectedCamDevice = storedDeviceId || storage.get.webCam()
      await checkCameraStatus(selectedCamDevice);
      const initialCamDevice = mediaDevices.filter(device => device.deviceId === selectedCamDevice)
      if (initialCamDevice.length === 0) throw new Error(PERMISSION_ERRORS.NO_DEVICE)
      else {
        const imageSrc = webcamRef.current.getScreenshot()
        setImgSrc(imageSrc)
        setShowPermissionModal(false)
        if (initialCapture) {
          proctorInitialCapture(imageSrc)
        } else {
          proctorGameplayCapture(imageSrc)
        }
      }
    } catch (err) {
      console.log('Error in camera permission', err)
      const classifiedError = classifyMediaDeviceError(err?.toString())
      setShowPermissionModal(classifiedError)
    }
  }

  const onRetake = useCallback(() => {
    setImgSrc('');
    setImageAnalysisData(null);
    setConsent(false);
  }, []);

  const proctorInitialCapture = async (imageSrc) => {
    const data = await detectFacesInImage(imageSrc)
    setImageAnalysisData(data)
  }

  useEffect(() => {
    let intervalId
    (async () => {
      if (initialCapture) {
        try {
          const mediaDevices = await navigator.mediaDevices.enumerateDevices()
          handleDevices(mediaDevices, true)
        } catch (err) { console.log(err) }
      }
      else {
        if (!isVideoProctored) {
          try {
            const mediaDevices = await navigator.mediaDevices.enumerateDevices()
            handleDevices(mediaDevices)
            if (onMountCamCheck) {
              const selectedCamDevice = storedDeviceId || storage.get.webCam()
              await checkCameraStatus(selectedCamDevice);
            };
            intervalId = setInterval(() => {
              capture()
            }, 1 * snapshotInterval * 1000)
          } catch (err) {
            console.log(err)
            const classifiedError = classifyMediaDeviceError(err?.toString())
            setShowPermissionModal(classifiedError)
          }
        }
      }
    })()
    return () => clearInterval(intervalId)
  }, [initialCapture, isVideoProctored, snapshotInterval])

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // System has woken up from sleep mode, so force a refresh of the Webcam component
        setWebCamKey(webCamKey + 1);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [webCamKey]);

  useEffect(() => {
    if (showPermissionModal) {
      window.dispatchEvent(new Event(PAUSE_TIMER_EVENT))
      window.dispatchEvent(new Event(HIDE_GAME_IFRAME_EVENT))
    }
  }, [showPermissionModal])

  return (
    <>
      {initialCapture ? (
        <div className="flex flex-col">
          <div className="flex justify-center w-full">
            {imgSrc ? (
              <div className="flex flex-col justify-center items-center">
                <img
                  src={imgSrc}
                  alt="user"
                  style={{
                    width: '300px',
                    height: '300px',
                    borderRadius: '50%',
                  }}
                />
                {imageAnalysisData !== null && (
                  <button
                    className="flex items-center font-medium br-20 mt-10"
                    style={{
                      color: 'white',
                      background: 'none',
                      borderStyle: 'none',
                      cursor: 'pointer',
                    }}
                    onClick={onRetake}
                  >
                    <span className='flex items-center mb-4 mr-4'>
                      <Camera />
                    </span>
                    {' '}
                    {t("webcam.take_again")}
                  </button>
                )}

                {imageAnalysisData === null ? (
                  <>
                    <LoadingOutlined className="size-24 mt-20" spin />
                    <p className="mt-10 size-14 font-light opacity-70 color-primary">
                      {t("webcam.clicked_loader_text")}
                    </p>
                  </>
                ) : imageAnalysisData?.isImageCritical ? (
                  <>
                    <p className="color-danger align-center size-14 font-medium mt-10 mb-10">
                      {`${imageAnalysisData.criticalReason} ${imageAnalysisData.criticalReason?.startsWith("Make") ? "" : !isRtlLayout ? `, please try again ${!isChrome ? `in Google Chrome` : ""}` : ""} `}
                    </p>
                  </>
                ) : (
                  <div className="mt-40 flex flex-col items-center">
                    <div className="flex items-center">
                      <Checkbox
                        checked={consent}
                        onChange={(e) => setConsent(e.target.checked)}
                        className={`${isRtlLayout ? "ml-14" : "mr-14"} relative bottom-8`}
                      />
                      <p className="size-18 color-secondary">
                        {t("webcam.agree_to_privacy_policy")}
                        <a
                          className="size-18 font-bold color-primary"
                          href={privacyPolicyLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {' '}
                          {t("webcam.privacy_policy")}
                        </a>
                        .
                      </p>
                    </div>
                    <Button
                      type="primary"
                      className="font-medium br-20"
                      style={{
                        width: '150px',
                      }}
                      onClick={() => confirmPhoto({ cameraSupport: true })}
                      loading={loading}
                      disabled={imageAnalysisData?.isImageCritical || !consent}
                    >
                      {t("webcam.continue")}
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center">
                {devices === null ? (
                  <LoadingOutlined className="size-24 mt-20" spin />
                ) : devices.length === 0 ? (
                  <>
                    <Result
                      status="warning"
                      title={
                        <p className="color-primary size-18">
                          {
                            `${"There seems to be some issue with your device’s camera. Please switch to some other device to take the assessment."}`
                          }
                        </p>
                      }
                      extra={<></>}
                    />
                  </>
                ) : (
                  <>
                    <p className="size-14 font-light color-primary">
                      {t("webcam.face_in_frame")}
                    </p>
                    <Webcam
                      key={webCamKey}
                      muted={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      mirrored
                      onError={(error) => {
                        console.error('Error in webcam: ', error);
                      }}
                      style={{
                        borderRadius: '20px',
                        width: isMobile ? '100%' : '350px',
                        height: isMobile ? '100%' : '350px', //isMobile ? '250px' : '350px',
                      }}
                      videoConstraints={{
                        width: 512,
                        height: 512,
                        facingMode: 'user',
                        deviceId: storedDeviceId,
                      }}
                    />
                    <p className="size-14 align-center font-medium opacity-70 color-primary mt-10">
                      {t("webcam.video_source")}
                    </p>
                    <Select
                      className="size-14 max-width-300"
                      suffixIcon={<Dropdown />}
                      value={storedDeviceId}
                      options={devices.map(({ label, deviceId }) => ({
                        label,
                        value: deviceId,
                      }))}
                      onChange={saveWebCamDevice}
                    />
                    <Button
                      type="primary"
                      className="font-medium br-20 mt-40"
                      style={{
                        width: '150px',
                      }}
                      disabled={!storedDeviceId}
                      onClick={capture}
                    >
                      {t("webcam.click_picture")}
                    </Button>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <Webcam
          style={{
            position: 'absolute',
            right: '100%',
          }}
          key={webCamKey}
          muted={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          mirrored
          videoConstraints={{
            deviceId: storedDeviceId,
          }}
        />
      )}
      <PermissionsModal
        visible={isVideoProctored ? false : !!showPermissionModal}
        type={showPermissionModal}
        device={PERMISSION_DEVICE_TYPES.CAMERA}
      />
    </>
  );
};

export default WebcamCapture;
